//reset and simple structure
@import "normalize";
@import "skeleton";

[v-cloak] {
  display: none;
}

// [v-cloak] > * { 
//   display:none 
// }
// [v-cloak]::before {
//   width: 100vw;
//   text-align: center;
//   font-family: Arial, Helvetica, sans-serif;
//   font-size: 16px;
//   content: "loading..." 
// }

//config
$mainColor: #A4DBE8;
$textColor: #6F6F6F;
$colorError: #e25950;

.mobile {
  display: none !important;
}

//fonts
@font-face {
  font-family: 'gothambook';
  src: url('gothambook-webfont.woff2') format('woff2'),
    url('gothambook-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gothammedium';
  src: url('gothammedium-webfont.woff2') format('woff2'),
    url('gothammedium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'gothambook';
  background-color: #fff;
  color: $textColor;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
}

a, a:hover {
  color: $mainColor;
}

.header {
  background-image: url("./../images/gift-cards-header.jpg");
  background-size: cover;
  margin-bottom: 74px;
  position: relative;
  z-index: 0;

  img {
    max-width: 114px;
    margin: 16px 0 56px 0;
  }
}

#loader {
  position: fixed;
  align-items: center;
  background-color: rgba(255,255,255,1.0);
  color: $mainColor;
  display: none;
  z-index: -1;

  &.show {
    display: flex;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  div {
    font-size: 20px;
    line-height: 24px;
    font-family: 'gothammedium';
    width: 100vw;
    text-align: center;
    font-weight: 500;

    img {
      max-width: 240px;
      margin-bottom: 32px;
    }

    h3 {
      font-size: 24px;
      line-height: 34px;
      display: block;
      text-align: center;
      color: #000;
    }
  }

}

h2 {
  font-family: 'gothammedium';
  color: $mainColor;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  font-weight: 500;
}

h3 {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

#giftCards {
  position: relative;

  &.loading {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
  }

  #initialState {
    // visibility: visible;
    transition: opacity 1s ease;
    // transform: translateX(0);
    opacity: 1;
  
    &.hide {
      opacity: 0;
      // visibility: hidden;
      transition: opacity 1s ease;
      // transform: translateX(-100vw);
    }

    &.float {
      position: absolute;
      display: none;
    }
  }

  #secondState {
    transition: transform 0.8s ease;
    transform: translateX(-100vw);
    // visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff;
  
    &.show {
      // visibility: visible;
      transition: transform 0.6s ease;
      transform: translateX(0);
      display: block;
    }

    &.float {
      display: none;
    }
  }

  #success {
    display: none;
    margin: 100px 0;

    &.show {
      display: block;
    }
    
    .container {
      max-width: 500px;
      text-align: center;
    }

    h3 {
      margin-bottom: 0;
    }

    img {
      width: 84%;
      margin: 34px auto;
    }

    a.button-primary,
    a.button-primary:hover {
      margin: 34px auto 0 auto;
      padding: 20px 0;
      display: block;
      max-width: 68%;
      text-decoration: none;
      border: 2px solid $mainColor;

      &+a.button-primary, 
      &+a.button-primary:hover {
        margin-top: 24px;
        background-color: #fff;
        border: 2px solid $mainColor;
        color: $mainColor;
      }
    }

    .button-primary,
    .button-primary:hover {
      font-family: 'gothammedium';
      border-radius: 0;
      border: none;
      background-color: $mainColor;
      font-size: 20px;
      color: #fff;
      padding: 24px 0;
      margin-bottom: 54px;
      width: 100%;
      height: auto;
      cursor: pointer;
      font-weight: 500;
    } 

  }

}

#template {

  img {
    display: block;
    width: 100%;
  }

  ul {
    list-style: none;
    overflow: hidden;
    padding: 2px;

    li {
      width: 31%;
      display: block;
      float: left;
      margin: 10px 0 0 0;

      &+li {
        margin-left: 3%;
      }

      img {
        // border: 2px solid #fff;
        cursor: pointer;
        border-radius: 5px;
        overflow: hidden;

        &.active {
          -moz-box-shadow: 0 0 0 2px $mainColor;
          -webkit-box-shadow: 0 0 0 2px $mainColor;
          box-shadow: 0 0 0 2px $mainColor;
        }
      }
    }
  }

}

#templateConfirm {
  max-width: 418px;

  img {
    width: 100%;
    margin-bottom: 36px;
  }

  strong {
    display: block;
    color: #000000;
    font-family: 'gothambook';
  }

  .hint {
    margin-top: 38px;

    &.no-gap {
      margin-top: 0;
    }
  }
}

.back {
  max-width: 36px;
  display: block;
  clear: both;
  margin: -26px 0 36px 0;
  cursor: pointer;
}


#paymentConfirmation {
  width: 418px;
  margin-left: 100px;
  padding-top: 28px;

  h3 {
    font-size: 22px;
    line-height: 24px;
    font-family: 'gothammedium';
    color: #000;
    padding: 24px 0;
    border-top: 1px solid #CFCFCF;
    border-bottom: 1px solid #CFCFCF;
    text-align: left;
    margin-bottom: 32px;
    font-weight: 500;

    &.small {
      font-size: 20px;
    }

    span {
      color: $mainColor;
      float: right;
      font-size: 24px;
    }
  }

  p {
    strong {
      display: block;
      color: #000000;
      font-family: 'gothambook';
    }
  }

  .item {
    overflow: hidden;
    margin-bottom: 28px;

    img {
      float: left;
      max-width: 64px;
      vertical-align: middle;
      margin-right: 18px;
    }

    p {
      float: left;
      margin-bottom: 0;
      line-height: 18px;

      &+p {
        float: right;
        color: $mainColor;
        font-size: 24px;
        line-height: 42px;
        font-family: 'gothammedium';
        font-weight: 500;
      }
    }
  }

  .message {
    margin-bottom: 48px;
  }

  #payment-request-button-info {
    margin-top: 36px;
    position: relative;
    font-style: normal;
    display: block;
    text-align: center;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 26%;
      display: block;
      height: 1px;
      background-color: #CFCFCF;
      top: 14px;
      left: 0;
    } 

    &::after {
      left: auto;
      right: 0;
    }
  }


  .paymentForm  {

    h3 {
      margin-top: 40px;
      padding: 0;
      border: none;
      font-size: 20px;
    }
  
    .row {
      display: -ms-flexbox;
      display: flex;
      margin: 0 0 10px 0;
    }
  
    .field {
      position: relative;
      width: 100%;
      height: 50px;
      margin: 0 0 8px 0;
    }
  
    .field.half-width {
      width: 48%;
  
      &+.half-width {
        margin-left: 4%;
      }
    }
  
    .field.quarter-width {
      width: calc(25% - 10px);
    }
  
    .baseline {
      display: none;
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      bottom: 0;
      background-color: #cfd7df;
      transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  
    label {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: normal;
      position: absolute;
      width: 100%;
      left: 14px;
      bottom: 4px;
      color: #AAAAAA;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      transform-origin: 0 50%;
      cursor: text;
      pointer-events: none;
      transition-property: color, transform;
      transition-duration: 0.3s;
      transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    
    .input {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      padding-bottom: 7px;
      color: #32325d;
      background-color: transparent;
      -webkit-tap-highlight-color: transparent;
    }
    
    #paymentForm-name {
      margin: 0;
      padding: 0;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: normal;
      -webkit-tap-highlight-color: transparent;
    }
    
    .input::-webkit-input-placeholder {
      color: transparent;
      // transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    
    .input::-moz-placeholder {
      color: transparent;
      // transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    
    .input:-ms-input-placeholder {
      color: transparent;
      // transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    
    .input.StripeElement {
      opacity: 0;
      // transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      will-change: opacity;
    }
    
    .input.focused,
    .input:not(.empty) {
      opacity: 1;
    }
    
    .input.focused::-webkit-input-placeholder,
    .input:not(.empty)::-webkit-input-placeholder {
      color: #cfd7df;
    }
    
    .input.focused::-moz-placeholder,
    .input:not(.empty)::-moz-placeholder {
      color: #cfd7df;
    }
    
    .input.focused:-ms-input-placeholder,
    .input:not(.empty):-ms-input-placeholder {
      color: #cfd7df;
    }
    
    .input.focused + label,
    .input:not(.empty) + label {
      color: #aab7c4;
      // transform: scale(0.85) translateY(-40px) translateX(-16px);
      cursor: default;
      display: none !important;
    }
    
    .input.focused + label {
      color: #000;
    }
    
    .input.invalid + label {
      color: #ffa27b;
    }
    
    .input.focused + label + .baseline {
      background-color: #D1D1D1;
    }
    
    .input.focused.invalid + label + .baseline {
      background-color: #e25950;
    }
    
    input, button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
      border-style: none;
      -webkit-tap-highlight-color: transparent;
    }
    
    input:-webkit-autofill {
      -webkit-text-fill-color: #32325d;
      transition: background-color 100000000s;
      -webkit-animation: 1ms void-animation-out;
    }
    
    .StripeElement--webkit-autofill {
      background: transparent !important;
    }
    
    input, button {
      -webkit-animation: 1ms void-animation-out;
    }

    .block {
      border: 1px solid #CFCFCF;
      padding: 0 8px;
      border-radius: 5px;
      height: 44px;
      // margin-bottom: 20px;
    }

    .error-message {
      padding: 18px;
      background: #FFEBEF;
      border-radius: 5px;
      color: #EB001B;
      margin-bottom: 32px;

      h3 {
        color: #EB001B;
        font-family: 'gothambook';
        font-size: 16px;
        padding-left: 24px;
        background-image: url(./../images/icon_error.png);
        background-size: 20px;
        background-position: left center;
        background-repeat: no-repeat;
        margin: 0 0 14px 0;
      }

      p {
        font-family: 'gothambook';
        font-size: 15px;
        margin: 0;
      }
    }

    #paymentForm-name {
      padding-left: 14px;
      height: 44px;

      &+label {
        bottom: 5px;
      }
    }

    #paymentForm-card-number,
    #paymentForm-card-expiry,
    #paymentForm-card-cvc {
      padding: 14px 0 12px 14px;
    }

    .pay-button {
      border-top: 1px solid #CFCFCF;
      overflow: hidden;
      margin-top: 24px;
      padding-top: 34px;

      span {
        font-family: 'gothammedium';
        display: inline-block;
        font-size: 34px;
        line-height: 60px;
        float: left;
        color: #000000;
        font-weight: 500;
      }

      button {
        font-family: 'gothammedium';
        font-size: 20px;
        width: 220px;
        height: 60px;
        line-height: 60px;
        left: 948px;
        top: 1122px;
        background: $mainColor;
        color: #ffffff;
        border-radius: 5px;
        cursor: pointer;
        float: right;
        text-transform: none;
        font-weight: 500;
      }

    }
    
  }
  
}

form {
  max-width: 474px;
  margin: 0 auto;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  input[type="email"],
  input[type="number"],
  input[type="search"],
  input[type="text"],
  input[type="tel"],
  input[type="url"],
  input[type="password"],
  textarea,
  input[type=email]:focus, 
  input[type=number]:focus, 
  input[type=search]:focus, 
  input[type=text]:focus, 
  input[type=tel]:focus, 
  input[type=url]:focus, 
  input[type=password]:focus, 
  textarea:focus, 
  select:focus {
    color: #5a5a5a;
    border: none;
    border-bottom: 1px solid #B4B4B4;
    width: 100%;
    border-radius: 0;
    padding: 8px 0;
    margin-bottom: 34px;
    -webkit-tap-highlight-color: transparent;
  }

  textarea {
    height: 136px;
  }

  .wordCount {
    text-align: right;
    display: block;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: #AAAAAA;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #AAAAAA;
  }

  ::placeholder {
    color: #AAAAAA;
  }

  #costs {
    margin: 34px 0;
    clear: both;

    ul {
      overflow: hidden;
      list-style: none;
      padding: 0;
      margin: 0 0 14px 0;

      li {
        display: block;
        float: left;
        border: 1.2px solid #C7C7C7;
        border-radius: 5px;
        width: 22.8%;
        text-align: center;
        padding: 14px 0;
        margin: 0;
        cursor: pointer;

        // &:hover {
        //   border: 1.2px solid $mainColor;
        //   color: $mainColor;
        // }

        // &.active {
        //   color: #fff;
        //   background-color: $mainColor;
        //   border-color: $mainColor;
        // }

        &.active {
          border: 1.2px solid $mainColor;
          color: $mainColor;
        }

        &+li {
          margin-left: 2%;
        }
      }
    }

    .aud {
      font-family: 'gothammedium';
      text-align: right;
      color: $mainColor;
      font-size: 24px;
      line-height: 58px;
      font-weight: 500;
    }

    div.costBox {
      border: 1px solid #B4B4B4;
      border-radius: 5px;

      span {
        width: 20%;
        text-indent: 14px;
        font-size: 26px;
        line-height: 58px;
        display: inline-block;
        float: left;
      }

      input {
        color: #6F6F6F;
        width: 80%;
        border: 0;
        margin: 0;
        padding: 0;
        font-size: 26px;
        line-height: 26px;
        font-family: 'gothammedium';
        height: 58px;
        line-height: 58px;
        font-weight: 500;
        -webkit-tap-highlight-color: transparent;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .giftCard {
    cursor: pointer;
    margin: 0 0 36px 0;
    display: inline-block;

    input {
      transform: scale(1.6);
      margin-left: 4px;
    }

    .label-body {
      font-family: 'gothammedium';
      font-weight: 500;
    }
  }

  #giftCardInfoContainer {
    display: none;

    &.show {
      display: block;
    }

    &.temp-hide {
      display: none;
    }

    textarea {
      margin-bottom: 0;
    }
  }

  input[type=submit].button-primary,
  input[type=submit].button-primary:hover,
  input[type=submit].button-primary:active,
  input[type=submit].button-primary:focus {
    font-family: 'gothammedium';
    border-radius: 0;
    border: none;
    background-color: $mainColor;
    font-size: 20px;
    color: #fff;
    padding: 24px 0;
    margin-bottom: 54px;
    width: 100%;
    height: auto;
    cursor: pointer;
    text-transform: none;
    font-weight: 500;
    -webkit-tap-highlight-color: transparent;
  } 

  span {
    &.error {
      display: none;
    }
  }

}

.error-limit {
  font-size: 14px;
  color: $colorError;
}

form.form-error {
    
  span {
    &.error {
      margin-top: -18px;
      display: block;
      margin-bottom: 26px;
      color: $colorError;
    }
  }

  label {
    &.error {
      color: $colorError;
    }
  }

  input {
    &.error {
      border-bottom: 1px solid $colorError;
    }
  }

  .final-checks {
    color: $colorError;
    margin-top: -48px;
    margin-bottom: 32px;
    text-align: center;
  }
}

/* Footer */
#footer {
	background: #fff;
  border: 1px solid #DEDEDE;
  margin-top: 96px;
	padding: 54px 76px 76px 76px;
	overflow: hidden;
  clear: both;
  position: relative;
  z-index: 0;

	.copyright {
		color: #949494;
		font-size: 14px;
		line-height: 25px;
		text-align: left;
		float: left;
		padding: 0;
		margin: 0;
	}

	.right-col {
    float: right;

		ul {
			padding: 0;
			margin: 0;
			list-style: none;
			display: inline-block;

			&.nav {
				margin-right: 48px;
			}

			li {
				display: inline-block;
				margin-left: 24px;
				padding: 0;

				a {
					color: #949494;
					font-size: 14px;
          line-height: 14px;
          text-decoration: none;

          &:hover {
            color: $mainColor;
          }
				}

				img {
					vertical-align: middle;
				}
			}
		}
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  border-bottom: 1px solid #B4B4B4;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    line-height: 18px;
  }

  // Box.
  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 22px;
    height: 20px;
    border: 1px solid #B7B7B7;
    border-radius: 2px;
    margin-top: -1px;
  }

  // Box hover
  // &:hover + label:before {
  //   background: $mainColor;
  // }
  
  // Box focus
  // &:focus + label:before {
  //   box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  // }

  // Box checked
  &:checked + label:before {
    border-color: $mainColor;
    background: $mainColor;
  }
  
  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 7px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 
      2px 0 0 white,
      4px 0 0 white,
      4px -2px 0 white,
      4px -4px 0 white,
      4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }
}

@media only screen and (max-width: 1024px) {

  #secondState {
    display: none;
    width: 100%;

    #templateConfirm {
      width: 42%;
    }

    #paymentConfirmation {
      width: 50%;
      margin-left: 8%;
      padding-top: 28px;
    }

  }


  #footer {
    
    p {
      &.copyright {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 24px;
      }
    }

    .right-col {
      float: none;
      text-align: center;

      ul.nav {
        display: block;
        text-align: center;
        margin: 0;

        li {
          margin: 0 0 24px 0;
          display: block;
        }
      }

      ul.social {
        margin: 24px 0;

        li {
          margin-left: 0;

          &+li {
            margin-left: 24px;
          }
        }
      }

    }

  }

}


@media only screen and (max-width: 780px) {

  #loader {
    &.show {

      div {
        margin-top: -26vh;
        padding: 0 20px;
        font-size: 18px;

        h3 {
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 14px;
        }
      }
    }
  }

  .desktop {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }

  .header {
    background-image: url("./../images/gift-cards-header-mobile.jpg");
    margin-bottom: 0;
    text-align: center;

    img {
      margin: 16px auto 30vh auto;
    }
  }

  h2, h3 {
    br {
      display: none;
    }
  }

  #giftCards {
    padding-top: 42px;
    border-radius: 24px;
    background-color: #fff;
    overflow: hidden;
    z-index: 10;
    margin-top: -30px;
    width: 100%;
    padding: 42px 20px;

    h2 {
      font-size: 26px;
      line-height: 32px;
    }

    h3 {
      color: #939393;
      margin-bottom: 32px;
    }

  }

  #template {
    margin-bottom: 32px;
  }

  form {

    #costs {
      margin-top: 8px;

      .six {
        width: 63%;

        &.aud {
          width: 36%;
          padding-right: 20px;
        }
      }

    }

    .giftCard {
      margin-bottom: 26px;
    }

    #giftCardInfoContainer {
      &.show {
        padding-bottom: 32px;
      }
    }

  }

  #giftCards {

    #initialState {
      transition-duration: 0s;
      transition-delay: 0s;

      &.hide {
        transition-duration: 0s;
        transition-delay: 0s;
        display: none;
      }
    }

    #secondState {
      position: relative;
      transition-duration: 0s;
      transition-delay: 0s;

      &.show {
        transition-duration: 0s;
        transition-delay: 0s;
      }
  
      #templateConfirm {
        padding: 42px 20px;
      }
  
      #paymentConfirmation {
        width: 100%;
        padding: 0;
        margin: 0;

        h3.header-title {
          padding-top: 0;
          border-top: none;
          position: relative;
          color: #000;
          text-align: center;

          img {
            top: 0;
            left: 0;
            position: absolute;
            display: inline-block;
            margin: 0;
          }
        }

        h3 {
          color: #000;

          &.small {
            text-align: left;
          }
        }

        .error-message {
          h3 {
            color: $colorError;
          }
        }

        .message {
          margin-bottom: 28px;

          &+.message+.message {
            margin-bottom: 48px;
          }
  
        }

      }
    
    }

    #proceed {
      padding: 18px 0;
    }

    #success {
      margin-top: 0;
  
      .container {
        width: 100%;
        padding: 0;

        h3 {
          font-size: 16px;
        }
      }
  
      a {
        &.button-primary,
        &.button-primary:hover {
          max-width: 84%;
          font-size: 18px;
        }
      }
    }

  }

  form input[type=submit].button-primary, 
  form input[type=submit].button-primary:hover, 
  form input[type=submit].button-primary:active, 
  form input[type=submit].button-primary:focus,
  #paymentConfirmation .paymentForm .pay-button button {
    font-size: 18px;
  }

  #paymentConfirmation .paymentForm .pay-button button {
    width: 56%;
    
    .pay-button span {
      font-size: 30px;
    }
  }

  form #costs ul li  {
    width: 22.4%;
  }

  #footer {
    margin-top: 24px;
    padding-left: 20px;
    padding-right: 20px;
  }

}